import { ReactNode } from 'react'
import Loader from 'components/global/Loader'
import Modal from 'components/global/Modal'

type ChildrenType = {
  children: ReactNode
}

export default function Layout({ children }: ChildrenType) {
  return (
    <>
      <Modal name="global" />
      <Loader />
      {children}
    </>
  )
}
