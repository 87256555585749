import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import LoaderSVG from '../../public/media/loader.svg'
import { css } from '@emotion/react'

export default function Loader() {
  const { loading } = useContext(GlobalContext)
  return loading ? (
    <div css={styles.loading}>
      <div>
        <LoaderSVG />
        <br />
        <span>{loading}</span>
      </div>
    </div>
  ) : (
    <></>
  )
}

const styles = {
  loading: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999999;
    span {
      display: block;
      max-width: 220px;
    }
    svg {
      margin: 20px;
      width: 50px;
      height: 50px;
    }
  `
}
