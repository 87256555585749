import { format } from 'date-fns'
/* const todayDate = format(new Date(), 'yyyy-MM-dd')
const todayDateTime = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
 */
const jwt = require('jsonwebtoken')

export function GTMPageView(url: string): any {
  interface PageEventProps {
    event: string
    page: string
  }

  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url
  }
  //@ts-ignore
  window && window.dataLayer && window.dataLayer.push(pageEvent)
  return pageEvent
}

// Helper method to wait for a middleware to execute before continuing
// And to throw an error when an error happens in a middleware
export function runMiddleware(req: any, res: any, fn: any): Promise<unknown> {
  return new Promise((resolve, reject) => {
    fn(req, res, (result: any) => {
      if (result instanceof Error) {
        return reject(result)
      }

      return resolve(result)
    })
  })
}

/**
 * Returns index of the equal value
 * If it returns -1 there is no equalized value
 *
 * @param {Array} array
 * @param {String} key
 * @param {any} value
 *
 */
export const getIndex = (array: any, key: string, value: any): number => {
  var selectIndex = -1
  if (!array?.length) return -1

  array?.filter(async (item: any, index: number) => {
    if (item[key] === value) {
      selectIndex = index
    }
  })

  return selectIndex
}

export function getArrayObject(array: any, key: string, value: any): any {
  var data: any = null

  if (array.length < 0) return null

  array.filter((item: any, index: number) => {
    if (item[key] === value) {
      data = item
    }
  })
  return data
}

export function getMapAddress(address: any): {
  city: string
  district: string
  country: string
} {
  var data = { city: '', district: '', country: '' }
  address?.map((item: any) => {
    if (item.types[0] == 'administrative_area_level_2')
      data.district = item?.long_name.toLocaleUpperCase('tr-TR')

    if (item.types[0] == 'administrative_area_level_1')
      data.city = item?.long_name.toLocaleUpperCase('tr-TR')

    if (item.types[0] == 'country')
      data.country = item?.long_name.toLocaleUpperCase('tr-TR')
  })

  return data
}

/**
 * Returns currency code with price
 *
 * @param {any} price
 *
 */
export function currency(price: number): any {
  /* if (typeof price !== 'number') {
    return price
  } */
  //return price.toFixed(2).replace(',', '.').replace('.', ',')
  var formatter = new Intl.NumberFormat('tr-tr', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })
  return formatter.format(price)
}

export function localDate(formatDate = 'yyyy-MM-dd'): string {
  const todayDate = format(new Date(), formatDate)

  return todayDate
}

export function generateID(number: number = 11): string {
  return Math.random()
    .toString(number)
    .slice(2, number + 2)
}

export function convertBirthday(birthday: any, splitChar: string): any {
  var resData: any
  var char = splitChar == '-' ? '/' : '-'
  if (birthday && splitChar) {
    var parseData = birthday.split(splitChar)
    resData = parseData[2] + char + parseData[1] + char + parseData[0]
  }
  return resData
}

export function truncate(str: string, limit: number = 20): string {
  if (str.length > limit) {
    str = str.split(' ').splice(0, limit).join(' ') + ' ...'
  }
  return str
}

export const storeLocaleControl = (context: any) => {
  return context.locale != 'tr' ? true : false
}

export function formatPhone(phoneNumber: string | number): string {
  var cleaned = ('' + phoneNumber).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/)
  if (match) {
    return [
      match[1],
      ' (',
      match[2],
      ') ',
      match[3],
      ' ',
      match[4],
      ' ',
      match[5]
    ].join('')
  }
  return ''
}

export async function isAuth(token: any): Promise<any> {
  //const token = cookies.get('token')
  var jwt_decoded = await jwt.decode(token)

  if (jwt_decoded && jwt_decoded.token.auth == true) {
    return jwt_decoded.token.tel
  } else {
    return false
  }
}

export const isEmpty = (value: any) => {
  if (typeof value === 'object' && !Array.isArray(value)) {
    return value && objectSize(value) > 0 ? true : false
  }
  if (Array.isArray(value)) {
    return value && value.length > 0 ? true : false
  }

  if (typeof value === 'string' && value != 'undefined') {
    return true
  }
  if (typeof value === 'number') {
    return true
  }

  return false
}

export function getHours(minute: number, yazim = false): string | number {
  if (yazim && minute) {
    var hours = minute > 60 ? Math.floor(minute / 60) : 0
    if (hours != 0) {
      var getMinutes = minute % 60
      var minutes = getMinutes ? ', ' + getMinutes + ' dk' : ''
      return hours + ' s' + minutes
    } else {
      return minute + ' dk'
    }
  }
  return minute
}

export function objectSize(obj: any): number {
  var size = 0,
    key
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++
  }
  return size
}

export function sortArray(array: any, sortKey: string, sortType: string): any {
  if (typeof array[0][sortKey] === 'string') {
    array.sort(function (a: any, b: any) {
      var nameA = a[sortKey].toUpperCase()
      var nameB = b[sortKey].toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    sortType == 'desc' && (array = array.reverse())
    return array
  }
  if (typeof array[0][sortKey] === 'number') {
    array.sort((a: any, b: any) => a[sortKey] - b[sortKey])
  }
  sortType == 'desc' && (array = array.reverse())
  return array
}
