import { FC, useContext, useEffect } from 'react'
import { css } from '@emotion/react'
import { GlobalContext } from 'context/GlobalContext'

type Props = {
  type?: string
  name?: string
  confirm?: string
  cancel?: string
  children?: any
  resConfirm?: any
  position?: string
  width?: number
}
var countdown: any
const Modal: FC<Props> = ({
  type = 'light',
  name = 'global',
  confirm,
  cancel = 'İptal',
  resConfirm,
  children,
  position = 'center',
  width = 660
}: Props) => {
  const { modalData, setContext } = useContext(GlobalContext)

  useEffect(() => {
    clearTimeout(countdown)
    if (modalData.timeout > 0) {
      countdown = setTimeout(
        () => {
          setContext({ modalData: {} })
        },
        modalData.timeout ? modalData.timeout : 3000
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData.timeout])

  useEffect(() => {
    if (modalData.name != name || !resConfirm) {
      return
    }
    function handleKeyUp(event: any) {
      switch (event.key) {
        case 'Enter':
          resConfirm(true)
          break
        case 'Escape':
          setContext('modalData', {})
          break
      }
    }

    window.addEventListener('keyup', handleKeyUp)
    return () => window.removeEventListener('keyup', handleKeyUp)
  }, [confirm, modalData.name, name, resConfirm, setContext])

  return (
    <>
      {modalData.name == name && (
        <div
          id="modal"
          css={styles.Modal}
          className={
            modalData.type ? 'global ' + modalData.type : 'global ' + type
          }
        >
          <div
            className="back"
            onClick={() => {
              setContext('modalData', {})
            }}
          ></div>

          {modalData.array ? (
            <div className="array_con">
              {modalData.array &&
                modalData.array.map((error: any, index: number) => (
                  <div
                    key={index}
                    className={
                      error.type
                        ? 'modal_inner array ' +
                          error.position +
                          ' ' +
                          error.type
                        : 'modal_inner array ' + error.position + ' ' + type
                    }
                    style={{ maxWidth: width }}
                  >
                    <button
                      className="close"
                      onClick={() => {
                        modalData.array.splice(index, 1)
                        setContext(
                          'modalData',
                          modalData.array.length <= 0 ? {} : modalData
                        )
                      }}
                    >
                      ✕
                    </button>
                    {error.title && <h2>{error.title}</h2>}
                    {error.message}
                  </div>
                ))}
            </div>
          ) : (
            <div
              //onMouseEnter={() => setContext('modalMouseEnter', true)}
              //onMouseLeave={() => setContext('modalMouseEnter', false)}
              className={'modal_inner ' + position}
              style={{ maxWidth: width }}
            >
              <button
                className="close"
                onClick={() => {
                  setContext('modalData', {})
                }}
              >
                ✕
              </button>
              {modalData.title && <h2>{modalData.title}</h2>}
              {modalData.text ? (
                <p className="p-0">{modalData.text}</p>
              ) : (
                children
              )}
              {confirm && !modalData.text && (
                <button
                  onClick={() => {
                    resConfirm(false)
                  }}
                  className="btn-m light f-left m-right-10 m-0 "
                >
                  {cancel}
                </button>
              )}
              {confirm && !modalData.text && (
                <button
                  onClick={() => {
                    resConfirm(true)
                  }}
                  className={
                    type == 'light'
                      ? 'btn-m primary m-0 m-right-10'
                      : 'btn-m m-0 f-right m-right-10'
                  }
                >
                  {confirm}
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Modal

const styles = {
  Modal: css`
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    *box-shadow: 0 10px 20px rgba(black, 0.2);
    z-index: 999999999;
    &.global {
      --margin: 40px;
    }
    .back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .modal_inner {
      position: absolute;
      display: inline-block;
      width: calc(100% - 40px);
      max-height: 95vh;
      overflow-y: scroll;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 33px 40px 38px;
      border-radius: 8px;
      background: var(--white);
      border-top: 5px solid var(--bg-color);
      z-index: 9999;

      @media only screen and (max-width: 480px) {
        padding: 15px 20px 20px;
      }
      &.left {
        left: var(--margin);
        transform: translateY(-50%);
      }
      &.left-top {
        left: var(--margin);
        top: var(--margin);
        transform: translate(0);
      }
      &.left-bottom {
        left: var(--margin);
        top: auto;
        bottom: var(--margin);
        transform: translate(0);
      }
      &.right {
        left: auto;
        right: var(--margin);
        transform: translateY(-50%);
      }
      &.right-top {
        left: auto;
        right: var(--margin);
        top: var(--margin);
        transform: translate(0);
      }
      &.right-bottom {
        left: auto;
        right: var(--margin);
        top: auto;
        bottom: var(--margin);
        transform: translate(0);
      }
      .close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 15px;
        cursor: pointer;
        &:hover {
          color: var(--errorColor);
        }
      }
      h2 {
        font-size: 24px;
        font-weight: 700;
        margin: 0 0 10px;
        color: var(--title-color);
      }

      label {
        *display: inline-block;
        text-align: center;
        font-size: 14px;
      }
    }
    .array_con {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      overflow-y: auto;
      .modal_inner {
        position: relative;
        display: block;
        margin-bottom: 20px;
      }
    }
  `
}
