import React, { useEffect } from 'react'
import { AppContext } from 'next/app'
import { axiosGet, setAxiosDefault } from 'helpers/apiHelpers'
import Router from 'next/router'
import GlobalContextProvider from 'context/GlobalContext'
import LayoutDefault from 'components/layout/LayoutDefault'
import 'styles/flatpickr.css'
import { GTMPageView } from 'helpers/filterHelper'
import { GTM } from 'helpers/gtm'

function UmbrellaApp({ Component, pageProps }: any) {
  const view = pageProps?.router?.pathname.replace('/', '')
  const gtag = pageProps?.starter?.site?.gtag || ''

  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url)
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <GlobalContextProvider {...pageProps} view={view}>
      <LayoutDefault>
        <GTM gtag={gtag} />
        <Component {...pageProps} />
      </LayoutDefault>
    </GlobalContextProvider>
  )
}

UmbrellaApp.getInitialProps = async (appContext: AppContext) => {
  await setAxiosDefault(appContext.ctx)
  const starter = await axiosGet('/starter', {
    shopping_settings: 1,
    delivery_fees: 1
  })

  const redirects = starter.redirects

  if (Array.isArray(redirects)) {
    redirects.forEach((redirect: any) => {
      const path = appContext.ctx.asPath
      if (redirect.request_url == path) {
        const res: any = appContext.ctx.res
        res.writeHead(301, { location: redirect.target_url })
        res.end()
      }
    })
  }

  return { pageProps: { starter: starter, router: appContext.router } }
}

export default UmbrellaApp
