import React, { createContext } from 'react'
import cookie from 'js-cookie'
import { axiosGet, axiosPost } from 'helpers/apiHelpers'
import router from 'next/router'

export const GlobalContext = createContext<any | null>(null)

type Props = {
  starter: any
  locale: string
  view: string
}

class GlobalContextProvider extends React.Component<Props> {
  componentDidUpdate(prevProps: any) {
    if (prevProps.view !== this.props.view) {
      this.setState({ view: this.props.view })
    }
  }
  async componentDidMount(this: any) {
    const theme = cookie.get('theme') || 'lightTheme'
    this.changeTheme(theme)

    setTimeout(() => {
      this.setState({ locale: router.locale, lang: router.locale })
    }, 500)

   /*  const service_data = localStorage.getItem('service')
    if (service_data) {
      setTimeout(() => {
        this.setContext({ service: JSON.parse(service_data) })
      }, 500)
    } */

    const fuel = localStorage.getItem('fuel')
    if (fuel) {
      setTimeout(() => {
        this.setContext({ fuel: JSON.parse(fuel) })
      }, 500)
    }
  }

  apiErrorShow = async (errorMessage: string) => {
    this.setState({
      modalData: {
        name: 'global',
        type: 'danger',
        title: 'Hata',
        text: errorMessage
      }
    })
  }

  setService = (keyOrObject: any, value?: any) => {
    var service: any

    if (keyOrObject instanceof Object) {
      service = { ...this.state.service, ...keyOrObject }
    } else {
      service = { ...this.state.service, [keyOrObject]: value }
    }
    this.setState({ service: { ...service } })

    //localStorage.setItem('service', JSON.stringify(service))
  }

  setContext = (keyOrObject: any, value?: any) => {
    if (keyOrObject instanceof Object) {
      this.setState({ ...this.state, ...keyOrObject })
    } else {
      this.setState({ [keyOrObject]: value })
    }
  }

  resetPassword = async (eposta: string) => {
    const res: any = await axiosPost('reset-password', {
      eposta
    })
    return res
  }

  changeTheme = (theme: string) => {
    document.body.classList.remove(this.state.theme)
    document.body.classList.add(theme)
    this.setState({ theme })
    cookie.set('theme', theme)
  }

  responseModal = (data: any, title: '') => {
    this.setContext('modalData', {
      name: 'global',
      type: data.error ? 'danger' : 'success',
      ...(title ? title : {}),
      text: data.message,
      timeout: 0
    })
  }

  setAuth = async (auth: any) => {
    if (auth && auth.userAuth) {
      this.setState({ user: auth.user })

      cookie.set('userAuth', auth.userAuth, {
        expires: 7,
        sameSite: 'none',
        secure: true
      })
      window.history.pushState(
        { prevUrl: window.location.href },
        '',
        router.asPath == '/cart' || router.asPath == '/login'
          ? '/'
          : router.asPath
      )
      router.reload()
    }
    if (auth && auth.error) {
      this.setState({
        modalData: {
          name: 'global',
          type: 'danger',
          title: 'Giriş',
          text: auth.message
        }
      })
    }
  }

  _local = (str: string) => {
    if (this.state?.local?.[str]) {
      return this.state?.local?.[str]
    }
    axiosGet('local', {
      local: str,
      lang: this.state.locale
    }).then((res: any) => {
      return res
    })
  }

  CDN_URL = process.env.NEXT_PUBLIC_CDN_URL

  state = {
    user: '',
    loading: false,
    theme: 'darkTheme',
    locale: 'tr',
    try_usd: 0,
    vehicle_class: 0,
    fuel: {},
    service: {
      type: 'transfer',
      city: '',
      start_point: {
        google_map_place_id: '',
        address: '',
        district: '',
        city: '',
        country: ''
      },
      end_point: {
        google_map_place_id: '',
        address: '',
        district: '',
        city: '',
        country: ''
      },
      start_datetime: '',
      has_return: false,
      duration: { text: '', value: 0 },
      distance: { text: '', value: 0 },
      extra_price_total: 0,
      toll_price_total: 0,
      car: {
        type: ''
      },
      toll_list: [],
      tolls: [
        {
          road: '',
          fee: 0
        }
      ],
      extra_services: [
        {
          service: '',
          fee: 0
        }
      ]
    },
    lang: this.props.locale || 'tr',
    site: this.props.starter.site,
    local: this.props.starter.local,
    menus: this.props.starter.menus,
    view: this.props.view || 'index',
    isNav: true,
    isCityActive: true,
    isLoginShow: false,
    modalPages: ['/cart', '/login'],
    modalData: { name: '', type: '', title: '', text: '' },
    _local: this._local,
    setContext: this.setContext,
    setService: this.setService,
    changeTheme: this.changeTheme,
    setAuth: this.setAuth,
    responseModal: this.responseModal,
    resetPassword: this.resetPassword,
    baseImageURL: this.CDN_URL + '/img_cache/',
    baseMediaURL: this.CDN_URL + '/media/'
  }

  render(this: any) {
    return (
      <GlobalContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </GlobalContext.Provider>
    )
  }
}

export default GlobalContextProvider
