import axios from 'axios'
import cookies from 'js-cookie'
import Router from 'next/router'
import nextCookie from 'next-cookies'

// Genel axios header ayarları
axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL
axios.defaults.headers.common['Token'] = process.env.NEXT_PUBLIC_TOKEN || ''
axios.defaults.headers.common['WebSite'] =
  process.env.NEXT_PUBLIC_WEB_SITE || ''
//axios.defaults.headers.common['Accept-Language'] = router.locale

var cookie: any = cookies.get()
if (cookie?.userAuth)
  axios.defaults.headers.common['userAuth'] = cookie?.userAuth

export const setAxiosDefault = async (ctx?: any) => {
  if (ctx) {
    cookie = nextCookie(ctx)
    axios.defaults.headers.common['Accept-Language'] = ctx.locale || 'tr'

    const isPreview = ctx?.query?.preview
    if (isPreview) axios.defaults.params = { status: isPreview }
  }

  if (cookie?.userAuth)
    axios.defaults.headers.common['userAuth'] = cookie?.userAuth

  if (!ctx && typeof window !== 'undefined') {
    axios.defaults.headers.common['Accept-Language'] = Router?.locale || 'tr'
  }
}

// Api bağlantı hata kontrolü
export const errorCheck = async (error: any) => {
  var statusCode = error?.response?.status || ''

  var message =
    error?.response?.data?.message ||
    statusCode + ' API isteği başarısız! Sistem yöneticisi ile görüşün.'

  var errorMessage = { error: true, message: message }

  return { ...errorMessage }
}

// Üye jwt token sorgulaması
export const checkAuth = (ctx: any) => {
  const { userAuth } = nextCookie(ctx)
  if (userAuth) {
    axios.defaults.headers.common['userAuth'] = userAuth
  }
  if (!userAuth) {
    if (typeof window !== 'undefined') {
      Router.push('/login')
      return
    } else {
      ctx.res.writeHead(302, { Location: '/login' })
      ctx.res.end()
    }
  }
  return userAuth
}

export const axiosGet = async (query_url: string, params: any = '') => {
  setAxiosDefault()
  return await axios
    .get(query_url, {
      params: {
        ...params
      }
    })
    .then((res) => {
      return res.data
    })
    .catch(async (error) => {
      return await errorCheck(error)
    })
}

export const axiosPost = async (query_url: string, data: any = '') => {
  setAxiosDefault()
  const response = await axios
    .post(query_url, data)
    .then((res) => {
      return res.data
    })
    .catch(async (error) => {
      return await errorCheck(error)
    })
  return response
}

export const axiosDelete = async (query_url: string, params: any = '') => {
  setAxiosDefault()
  const response = await axios
    .delete(query_url, {
      params: {
        ...params
      }
    })
    .then((res) => {
      return res.data
    })
    .catch(async (error) => {
      return await errorCheck(error)
    })
  return response
}
